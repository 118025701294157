import React from 'react';
import { StrapiMenu } from '@/api/menu';
import { StrapiFooter } from '@/api/footer';
import { StrapiService } from '@/api/services-page';
import { Menu } from '@/components/menu';
import { Footer } from '@/components/footer';
import { Section1 } from '@/components/sections/service-detail/Section1';
import { Section2 } from '@/components/sections/service-detail/Section2';
import { Section3 } from '@/components/sections/service-detail/Section3';
import { Section4 } from '@/components/sections/service-detail/Section4';
import { Head as HeadComponent } from '@/components/head';

export default function ServiceDetail({
  pageContext: { strapiMenu, strapiFooter, strapiService },
}: {
  pageContext: {
    strapiMenu: StrapiMenu;
    strapiFooter: StrapiFooter;
    strapiService: StrapiService;
  };
}) {
  const headMeta = {
    title: 'Treeline Interactive',
    meta: {
      title: 'Treeline Interactive - Services',
      description: 'Treeline Interactive - Services',
      keywords: 'services, mobile app development, mobile application development, android app development, mobile app development, mobile application development, ios developer, ios development',
    },
  };
  return (
    <>
      <HeadComponent
        defaultTitle={headMeta.title}
        defaultMeta={headMeta.meta}
        metadata={strapiService.attributes.metadata}
        image={strapiService.attributes.metadata?.OGImage?.data?.attributes.url
          || strapiMenu.logo.data.attributes.url} />
      <Menu strapiMenu={strapiMenu} />
      <Section1
        strapiSection={strapiService.attributes.section1}
        name={strapiService.attributes.name}
        image={undefined}
      />
      <Section2 strapiSection={strapiService.attributes.section2} />
      <Section3 strapiSection={strapiService.attributes.section3} />
      <Section4 strapiSection={strapiService.attributes.section4} />
      <Footer strapiFooter={strapiFooter} />
    </>
  );
}
