import React, { FC } from 'react';
import { Container, Grid } from '@mui/material';
import { navigate } from 'gatsby';
import { ServicesDetailSection4 } from '@/api/services-page';
import { TrackedButton } from '@/components/tracked-button';
import { MarkdownContent } from '@/components/markdown-content';
import { MainColumn } from '@/components/main-column';
import { CaseStudyCard } from '@/components/case-study';
import { BackLink } from '@/components/back-link';

type IServiceDetailSection4 = {
  strapiSection: ServicesDetailSection4;
};

export const Section4: FC<IServiceDetailSection4> = ({ strapiSection }) => (
  <>
    <MainColumn
      sx={{
        pt: { xs: 6, md: 10 },
      }}
    >
      <Grid container alignContent={'center'} justifyContent={'space-between'}>
        <Grid item sx={{ mb: 2 }}>
          <MarkdownContent
            content={strapiSection?.paragraph}
            sx={{
              'h1, h2, h3, h4, h5, h6': {
                mb: 3,
              },
            }}
          />
        </Grid>
        <Grid item sx={{ mb: 5 }}>
          <Grid
            container
            flexDirection={'column'}
            height={'100%'}
            justifyContent={'center'}
          >
            <TrackedButton
              variant="outlined"
              onClick={() => navigate(strapiSection?.button?.link)}
            >
              {strapiSection?.button?.text
                ? strapiSection.button.text
                : 'Button'}
            </TrackedButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        alignContent="center"
        spacing={{ xs: 0, sm: 4 }}
        sx={{
          mb: { xs: 6, md: 9 },
        }}
      >
        {strapiSection?.caseStudies?.data
          .filter((caseStudy) => !!caseStudy)
          .map(
            ({
              attributes: {
                gradientColor,
                isSeparateSubpage,
                slug,
                icon: { data: icon },
                image: {
                  data: {
                    attributes: { url },
                  },
                },
              },
            }) => (
              <CaseStudyCard
                isSeparateSubpage={isSeparateSubpage}
                key={`${url}`}
                gradientColor={gradientColor}
                slug={slug}
                icon={icon}
                url={url || ''}
              />
            ),
          )}
      </Grid>
    </MainColumn>
    <Container
      sx={{ backgroundColor: 'greyscale.cultured' }}
      disableGutters
      maxWidth={false}
    >
      <MainColumn
        sx={{
          py: 8,
          '> *': {
            verticalAlign: 'middle',
          },
        }}
      >
        <BackLink to="/services" text="Go Back to All Services" />
      </MainColumn>
    </Container>
  </>
);
