import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { ServicesDetailSection3 } from '@/api/services-page';
import { SectionWithFloatingImage } from '@/components/section-with-floating-image';
import { MainColumn } from '@/components/main-column';
import { MarkdownContent } from '@/components/markdown-content';
import { BulletList } from '@/components/bullet-list';
import { StyledParagraphImage } from './ServiceDetail.style';
import getGatsbyImageData from '@/utils/getGatsbyImageData';

type IServiceDetailSection3 = {
  strapiSection: ServicesDetailSection3;
};

export const Section3: FC<IServiceDetailSection3> = ({ strapiSection }) => {
  const paragraph2ImageData = getGatsbyImageData(
    strapiSection?.paragraph2Image?.data,
    {},
  );
  return (
    <>
      <MainColumn
        sx={{
          py: { xs: 6, sm: 10 },
        }}
      >
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              fontSize: 0,
              pr: { xs: '0px', sm: 8 },
              mb: { xs: 4, md: '0px' },
            }}
          >
            {paragraph2ImageData && (
              <StyledParagraphImage
                alt={
                  strapiSection?.paragraph2Image?.data?.attributes
                    ?.alternativeText || ''
                }
                image={paragraph2ImageData}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} order={{ xs: 2, sm: 1 }}>
            <MarkdownContent
              content={strapiSection?.paragraph2}
              sx={{
                mb: 3,
                'h1, h2, h3, h4, h5, h6': {
                  mb: 2,
                },
                '> p': {
                  marginBlockStart: '0px',
                  ':last-of-type': {
                    mb: '0px',
                  },
                },
              }}
            />
            {strapiSection?.paragraph2List?.map(
              ({ text }: { text: string }, idx: number) => (
                <BulletList key={`${text}${idx}`} text={text} />
              ),
            )}
          </Grid>
        </Grid>
      </MainColumn>
      <Grid
        xs={12}
        sx={{
          alignItems: 'center',
          py: { xs: 6, sm: 10 },
        }}
        container
      >
        <SectionWithFloatingImage
          contentBeforeLogos={strapiSection?.paragraph3 || ''}
          contentAfterLogos={strapiSection?.paragraph3BelowLogos || ''}
          logos={strapiSection?.paragraph3Logos?.data}
          floatingImageMobile={strapiSection?.paragraph3Image?.data}
          floatingImageDesktop={strapiSection?.paragraph3Image?.data}
        />
      </Grid>
    </>
  );
};
