import React, { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { ServicesDetailSection2 } from '@/api/services-page';
import { MainColumn } from '@/components/main-column';
import { TrackedButton } from '@/components/tracked-button';

type IServiceDetailSection2 = {
  strapiSection: ServicesDetailSection2;
};

export const Section2: FC<IServiceDetailSection2> = ({ strapiSection }) => (
  <MainColumn
    sx={{
      py: { xs: 6, sm: 10 },
    }}
  >
    <Grid
      container
      spacing={3}
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: { xs: 5, md: 6 },
      }}
    >
      <Grid item>
        <Typography variant="h3">{strapiSection?.title}</Typography>
      </Grid>
      <Grid item>
        <TrackedButton variant="outlined" href={'/contact-us'}>
          {strapiSection?.buttonText}
        </TrackedButton>
      </Grid>
    </Grid>
  </MainColumn>
);
