import React, { FC } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { ServicesDetailSection1 } from '@/api/services-page';
import { MarkdownContent } from '@/components/markdown-content';
import { DataAttr, StrapiImage } from '@/api/types';
import { MainColumn } from '@/components/main-column';
import { MENU_HEIGHT } from '@/constants';
import { BulletList } from '@/components/bullet-list';
import getGatsbyImageData from '@/utils/getGatsbyImageData';
import {
  StyledImage,
  StyledParagraphImage,
} from '@/components/sections/service-detail/ServiceDetail.style';

type IServiceDetailSection1 = {
  strapiSection: ServicesDetailSection1;
  name: string;
  image: DataAttr<StrapiImage> | undefined;
};

export const Section1: FC<IServiceDetailSection1> = ({
  strapiSection,
  name,
  image,
}) => {
  const imageData = getGatsbyImageData(image?.data);
  const paragraphImageData = getGatsbyImageData(
    strapiSection?.paragraph1Image?.data,
    {},
  );
  return (
    <>
      <Container
        disableGutters
        maxWidth={false}
        sx={{
          backgroundColor: 'greyscale.cultured',
        }}
      >
        <MainColumn
          sx={{
            pt: { xs: 5, md: 10 },
            pb: { xs: 8, md: 10 },
            mt: `${MENU_HEIGHT}px`,
          }}
        >
          <Typography variant="h1">{name}</Typography>
        </MainColumn>
      </Container>
      <Container disableGutters maxWidth={false}>
        {imageData && (
          <StyledImage
            alt={image?.data?.attributes?.alternativeText || ''}
            image={imageData}
          />
        )}
      </Container>
      <MainColumn
        sx={{
          py: { xs: 6, sm: 10 },
        }}
      >
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xs={12} sm={6} order={{ xs: 2, sm: 1 }}>
            <MarkdownContent
              content={strapiSection?.paragraph1}
              sx={{
                mb: 3,
                'h1, h2, h3, h4, h5, h6': {
                  mb: 2,
                },
                '> p': {
                  marginBlockStart: '0px',
                  ':last-of-type': {
                    mb: '0px',
                  },
                },
              }}
            />
            {strapiSection?.paragraph1List?.map(
              ({ text }: { text: string }, idx: number) => (
                <BulletList key={`${text}${idx}`} text={text} />
              ),
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            order={{ xs: 1, sm: 2 }}
            sx={{
              fontSize: 0,
              pl: { xs: '0px', sm: 8 },
              mb: { xs: 4, md: '0px' },
            }}
          >
            {paragraphImageData && (
              <StyledParagraphImage
                alt={
                  strapiSection?.paragraph1Image?.data?.attributes
                    ?.alternativeText || ''
                }
                image={paragraphImageData}
              />
            )}
          </Grid>
        </Grid>
      </MainColumn>
    </>
  );
};
