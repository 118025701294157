import { styled } from '@mui/system';
import { GatsbyImage } from 'gatsby-plugin-image';

export const StyledImage = styled(GatsbyImage)({
  minHeight: '320px',
  width: '100%',
});

export const StyledParagraphImage = styled(GatsbyImage)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('xs')]: {
    height: '343px',
  },
  [theme.breakpoints.up('md')]: {
    height: 'auto',
  },
}));
